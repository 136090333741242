<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas"></nav>
    <nav class="sidebar sidebar-offcanvas" id="sidebar" style="position: fixed;z-index: 10;top: 70px">
      <ul class="nav d-flex" style="height: calc(100vh - 70px);margin-bottom: 0px;">
        <li class="nav-item navbar-brand-mini-wrapper">
          <a class="navbar-brand brand-logo-mini" href="#"><img src="@/assets/images/logo-mini.svg" alt="logo" /></a>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/calendar">
            <span class="menu-title">{{$t("sidebar.calendar_title")}}</span>
            <i class="mdi mdi-24px mdi-calendar-multiselect-outline menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/booking">
            <span class="menu-title">{{$t("sidebar.booking_title")}}</span>
            <i class="mdi mdi-24px mdi-receipt-text-plus-outline menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/transports">
            <span class="menu-title">{{$t("sidebar.transport_list_title")}}</span>
            <i class="mdi mdi-24px mdi-list-box-outline menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-if="manager">
          <span class="nav-link" v-b-toggle="'administration'">
            <span class="menu-title">{{$t("sidebar.admin_title")}}</span>
            <i class="mdi mdi-clipboard-account-outline mdi-24px menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="administration">
            <ul class="nav flex-column sub-menu">
              <!--<li class="nav-item">
                <router-link class="nav-link" to="/admin/drivers/">Driver registration</router-link>
              </li>-->
              <li class="nav-item">
                <router-link class="nav-link" to="/admin/tms/">{{$t("sidebar.admin_tm_title")}}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/admin/requests/">{{$t("sidebar.admin_requests_title")}}</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-if="manager">
          <span class="nav-link" v-b-toggle="'config'">
            <span class="menu-title">{{$t("sidebar.config_title")}}</span>
            <i class="mdi mdi-store-cog-outline mdi-24px menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="config">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/config/general/">{{$t("sidebar.config_general_title")}}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/config/schedule/">{{$t("sidebar.config_schedule_title")}}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/config/dates/">{{$t("sidebar.config_special_title")}}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/config/trucks/">{{$t("sidebar.config_trucks_title")}}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/config/goods/">{{$t("sidebar.config_goods_title")}}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/config/terminals/">{{$t("sidebar.config_terminals_title")}}</router-link>
              </li>
              <!--<li class="nav-item">
                <router-link class="nav-link" to="/config/server/">{{$t("sidebar.config_server_title")}}</router-link>
              </li>-->
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item mt-auto" v-on:click="collapseAll" style="padding:0px;cursor: pointer" @click="userFeedback()">
          <div class="nav-link" style="padding: 25px">
            <span class="menu-title"><strong>Report a bug</strong></span>
            <i class="mdi mdi-24px mdi-bug-outline menu-icon" style="color: inherit"></i>
          </div>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import * as Sentry from '@sentry/vue';
export default {
  // eslint-disable-next-line
  name: 'sidebar',
 data () {
    return {
      manager: false,
      collapses: [ 
     { show: false },
     { show: false },
     { show: false }
    ]
    }
  },

  methods: {
    userFeedback(){
      let eventId= Sentry.lastEventId();
      if (!eventId){
        eventId = Sentry.captureMessage("User feedback");
      }
      Sentry.showReportDialog({ eventId: eventId });
    },
    collapseAll() {
      var exp_element = document.getElementsByClassName("show");
      if (exp_element.length > 0) {
        var elm_id = exp_element[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    }
  },
  mounted () {
    const permission = localStorage.getItem("wtm_manager")
    if (permission){this.manager = permission==="true"}
    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },
  watch:{
    $route () {
       document.querySelector('#sidebar').classList.toggle('active');
    }
  } 
}
</script>