<template>
  <section class="main-view">
    <div class="container-scroller">
      <div class="container-fluid content-wrapper">
        <b-card no-body>
          <b-card-body>
            <h3>{{ this.$route.params['id'] }}</h3>
            <h4>{{ this.item.status }}</h4>
            <h4>{{ new Date(this.item.start).toLocaleString() }}</h4>
            <b-row>
              <b-col lg="6" style="overflow: clip">
                <ul>
                  <li v-for="(value, key) in this.detailLeft" :key="key">{{ key }}: {{ value }}</li>
                </ul>
              </b-col>
              <b-col lg="6" style="overflow: clip">
                <ul>
                  <li v-for="(value, key) in this.detailRight" :key="key">{{ key }}: {{ value }}</li>
                </ul>
              </b-col>
            </b-row>
            <b-button v-if="(item.status==='ACCEPTED'||item.status==='LATE')&&!this.$route.query.token" variant="primary" @click="getPDF">Download Transport Document
            </b-button>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  //eslint-disable-next-line
  name: "status",
  data() {
    return {
      detailLeft: null,
      detailRight: null,
      item: null
    }
  },
  mounted() {
    if (this.$route.query.token){
      this.itemProviderAnonym()
    }else {

      this.itemProvider()
    }
  },
  methods: {
    lengthFormatter(length) {
      let l = length.split('T')[1]
      l = l.replace("H", " " + this.$t('transports.hours') + " ")
      l = l.replace("M", " " + this.$t('transports.minutes') + " ")
      l = l.replace("S", " " + this.$t('transports.seconds') + " ")
      return l
    },
    getPDF() {
      let promise = fetch("/api/transport/" + this.$route.params['id'] + "/pdf", {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
      })
      return promise.then((res) => {
        res.blob().then(blob => {
          let file = window.URL.createObjectURL(blob);
          window.location.assign(file);
        });
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
        this.$bvToast.toast('An error occurred during the query: ' + error.toString(), {
          role: 'alert',
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        this.items = []
        this.isBusy = false
        return []
      })
    },
    itemProvider() {
      this.isBusy = true
      let promise = fetch("/api/transport/" + this.$route.params['id'], {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
      })
      return promise.then((data) => {
        data.json().then((item) => {
          this.item = item
          let left = {}
          let right = {}
          left["Transport ID"] = item.id
          left["Length"] = this.lengthFormatter(item.length)
          left["Direction"] = item.arrive ? "Import" : "Export"
          left["Good type"] = item.goodType?.name
          left["Handling instructions"] = item.goodType?.handlingInstructions
          left["Pallet Size"] = item.palletSize
          left["Order Number"] = item.orderNr
          left["Partial Shipment ID"] = item.partial
          left["Company"] = item.company

          right["Terminal"] = item.terminalName
          right["Truck type"] = item.truckType?.name
          right["License Plate Number"] = item.licensePlate
          right["Terminal Manager name"] = item.acceptor?.name
          right["Terminal Manager number"] = item.acceptor?.phoneNr
          right["Dispatcher name"] = item.dispatcher?.name
          right["Dispatcher phone number"] = item.dispatcher?.phoneNr
          right["Leave by"] = new Date(item.end).toLocaleString()
          right["Note"] = item.notes
          this.detailLeft = left
          this.detailRight = right
        })
        this.isBusy = false
        return (this.items)
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
        this.$bvToast.toast('An error occurred during the query: ' + error.toString(), {
          role: 'alert',
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        this.items = []
        this.isBusy = false
        return []
      })
    },
  itemProviderAnonym() {
    this.isBusy = true
    let promise = fetch("/api/anon/"+this.$route.query.token+"/transport/" + this.$route.params['id'], {
      method: "GET"
    })
    return promise.then((data) => {
      data.json().then((item) => {
        this.item = item
        let left = {}
        let right = {}
        left["Transport ID"] = item.id
        left["Length"] = this.lengthFormatter(item.length)
        left["Direction"] = item.arrive ? "Import" : "Export"
        left["Good type"] = item.goodType?.name
        left["Handling instructions"] = item.goodType?.handlingInstructions
        left["Pallet Size"] = item.palletSize
        left["Order Number"] = item.orderNr
        left["Partial Shipment ID"] = item.partial
        left["Company"] = item.company

        right["Terminal"] = item.terminalName
        right["Truck type"] = item.truckType?.name
        right["License Plate Number"] = item.licensePlate
        right["Terminal Manager name"] = item.acceptor?.name
        right["Terminal Manager number"] = item.acceptor?.phoneNr
        right["Dispatcher name"] = item.dispatcher?.name
        right["Dispatcher phone number"] = item.dispatcher?.phoneNr
        right["Leave by"] = new Date(item.end).toLocaleString()
        right["Note"] = item.notes
        this.detailLeft = left
        this.detailRight = right
      })
      this.isBusy = false
      return (this.items)
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error)
      this.$bvToast.toast('An error occurred during the query: ' + error.toString(), {
        role: 'alert',
        title: 'Error',
        variant: 'danger',
        solid: true
      })
      this.items = []
      this.isBusy = false
      return []
    })
  },
  }
}
</script>

<style>
html,
body {
  background: #ecf0f4;
}
</style>