import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'
import status from "@/component/status";


Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: layout,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/pages/ftf')
        }
      ]
    },
    {
      path: '/status/:id',
      name: 'status',
      component: status,
    },
    {
      path: '/calendar',
      component: layout,
      children: [
        {
          path: '',
          name: 'calendar',
          component: () => import('@/pages/calendar')
        }
      ]
    },
    {
      path: '/booking',
      component: layout,
      children: [
        {
          path: '',
          name: 'booking',
          component: () => import('@/pages/booking')
        }
      ]
    },
    {
      path: '/transports',
      component: layout,
      children: [
        {
          path: '',
          name: 'transports',
          component: () => import('@/pages/transports')
        }
      ]
    },
    {
      path: '/admin/dispatchers',
      component: layout,
      children: [
        {
          path: '',
          name: 'dispatchers',
          component: () => import('@/pages/admin/dispatchers')
        }
      ]
    },
    /*{
      path: '/admin/drivers',
      component: layout,
      children: [
        {
          path: '',
          name: 'drivers',
          component: () => import('@/pages/admin/drivers')
        }
      ]
    },*/
    {
      path: '/admin/tms',
      component: layout,
      children: [
        {
          path: '',
          name: 'tms',
          component: () => import('@/pages/admin/tms')
        }
      ]
    },
    {
      path: '/admin/requests',
      component: layout,
      children: [
        {
          path: '',
          name: 'requests',
          component: () => import('@/pages/admin/requests')
        }
      ]
    },
    {
      path: '/config/general',
      component: layout,
      children: [
        {
          path: '',
          name: 'general',
          component: () => import('@/pages/config/general')
        }
      ]
    },
    {
      path: '/config/schedule',
      component: layout,
      children: [
        {
          path: '',
          name: 'schedule',
          component: () => import('@/pages/config/schedule')
        }
      ]
    },
    {
      path: '/config/dates',
      component: layout,
      children: [
        {
          path: '',
          name: 'dates',
          component: () => import('@/pages/config/dates')
        }
      ]
    },
    {
      path: '/config/trucks',
      component: layout,
      children: [
        {
          path: '',
          name: 'trucks',
          component: () => import('@/pages/config/trucks')
        }
      ]
    },
    {
      path: '/config/goods',
      component: layout,
      children: [
        {
          path: '',
          name: 'goods',
          component: () => import('@/pages/config/goods')
        }
      ]
    },
    {
      path: '/config/terminals',
      component: layout,
      children: [
        {
          path: '',
          name: 'terminals',
          component: () => import('@/pages/config/terminals/terminals')
        }
      ]
    },
    {
      path: '/config/server',
      component: layout,
      children: [
        {
          path: '',
          name: 'server',
          component: () => import('@/pages/config/server')
        }
      ]
    },
    {
      path: '/users',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.open("https://auth.tttsolutions.hu/admin/FTF/console/#/FTF/groups/e9fc1f34-6158-46e6-9c4f-6929b8d7778e")
      }
    },
    {
      path: '/logout',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://auth.tttsolutions.hu/realms/FTF/protocol/openid-connect/logout")
      }
    },
    {
      path: '/profile',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.open("https://auth.tttsolutions.hu/realms/FTF/account/")
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
