import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CountryFlag from 'vue-country-flag'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Keycloak from "keycloak-js"
import VueI18n from 'vue-i18n'
import i18n from './i18n'
import 'vue-select/dist/vue-select.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18n)
Vue.component('country-flag', CountryFlag)
Vue.config.productionTip = false
import * as Sentry from "@sentry/vue";

let initOptions = {
  url: 'https://auth.tttsolutions.hu/', realm: 'FTF', clientId: 'wtm-ui', onLoad: 'check-sso'
}
let keycloak = Keycloak(initOptions);

Sentry.init({
  Vue,
  dsn: "https://60c6247ed77bd11653fb18d444f45b3a@o4505918480187392.ingest.sentry.io/4505918482350080",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  new Vue({
    router,
    el: '#app',
    i18n,
    render: h => h(App, { props: { keycloak: keycloak } })
  }).$mount('#app')
  if (!auth) {
    console.log("Not Authenticated")
  } else {
    console.log("Authenticated");
    console.log(keycloak.tokenParsed)
    const username = keycloak.tokenParsed.given_name + ' ' + keycloak.tokenParsed.family_name
    const monogram = keycloak.tokenParsed.given_name[0]+keycloak.tokenParsed.family_name[0]
    const token = keycloak.token
    let user = false
    let manager = false
    console.log(keycloak.tokenParsed)
    user = keycloak.tokenParsed.realm_access.roles.includes("wtm_user")//TODO Migrate to wtm client
    manager = keycloak.tokenParsed.realm_access.roles.includes("ROLE_WAREHOUSE_MANAGER")//TODO Migrate to wtm client
    localStorage.setItem("username",username)
    localStorage.setItem("monogram",monogram)
    localStorage.setItem("token",token)
    localStorage.setItem("wtm_user",user.toString())
    localStorage.setItem("wtm_manager",manager.toString())
  }


//Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        localStorage.setItem("token",keycloak.token)
      }
    }).catch(() => {
      console.log('Failed to refresh token');
    });
  }, 6000)

}).catch((reason) => {
  console.log(reason)
  console.log("Authenticated Failed");
});
