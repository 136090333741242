<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import {provide,ref} from "vue";
export default {
  name: 'app',
  props: {
    keycloak: Object
  },
  created() {
    if (this.$router.currentRoute.name !== "status") {
      if (!this.keycloak.authenticated) {
        console.log("Prompt for login")
        this.keycloak.login()
      }
    }
  },
  mounted() {
    const user = ref({})
    provide("loggedInUser", user)
    this.interval = setInterval(this.loadUserInfo,100,user)
  },
  methods: {
    loadUserInfo(user){
      if (localStorage.getItem("token")!==null){
        fetch("/api/me/info", {
              headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("token")
              })
            }
        ).then((data) => {
          data.json().then((json) => {
            clearInterval(this.interval)
            user.value = json
          })
        })
      }
    }
  },
  data(){
    return{
      interval: null
    }
  }
}
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "./assets/scss/style";
</style>
