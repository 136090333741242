<template>
  <b-navbar id="template-header" class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
            toggleable="false" style="z-index: 998">
    <b-modal
        id="prevent-access"
        ref="modal"
        :title='$t("header.modal.title")'
        @hidden="handleClose"
        @ok="handleClose"
        footer-bg-variant="light"
        header-bg-variant="light"
        body-bg-variant="light"
        cancel-disabled
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        ok-only
        :ok-title="$t('header.signOut')"
        :size="state==='unknown'?'xl':'md'"
    >
      <div v-if="state==='unknown'">
        <div v-if="form.type">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-row>
              <b-col cols="10">
                <b-form-group id="firstname-group" :label='$t("header.modal.first_name_label")' label-for="firstname-group">
                  <b-form-input
                      id="firstname"
                      v-model="form.firstName"
                      :placeholder='$t("header.modal.first_name_placeholder")'
                      readonly
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="lastname-group" :label='$t("header.modal.last_name_label")' label-for="lastname">
                  <b-form-input
                      id="lastname"
                      v-model="form.lastName"
                      :placeholder='$t("header.modal.last_name_placeholder")'
                      readonly
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="2" style="display: flex;">
                <b-form-group label=" " style="align-self: center; width: inherit">
                  <b-img
                      style="margin-top: 5px; max-height: 130px"
                      :src="dispatcherImage"
                      v-bind="dispatcherImage"
                      rounded
                      center
                      fluid
                      :alt='$t("header.modal.dispatcher_image_alt")'>
                  </b-img>
                </b-form-group>
              </b-col>
            </b-form-row>
            <div v-if="form.type==='DISPATCHER'">
              <b-form-row>
                <b-col>
                  <b-form-group id="companyName-group" :label='$t("header.modal.company_name_label")' label-for="companyName">
                    <b-form-input
                        id="companyName"
                        v-model="form.companyName"
                        :placeholder='$t("header.modal.company_name_placeholder")'
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="companyTaxId-group" :label='$t("header.modal.tax_id_label")' label-for="companyTaxId">
                    <b-form-input
                        id="companyTaxId"
                        v-model="form.companyTaxId"
                        :placeholder='$t("header.modal.tax_id_placeholder")'
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group id="phone-group" :label='$t("header.modal.phone_number_label")' label-for="phone">
                    <b-form-input
                        id="phone"
                        v-model="form.phoneNr"
                        type="tel"
                        :placeholder='$t("header.modal.phone_number_placeholder")'
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="email-group" :label='$t("header.modal.email_label")' label-for="email">
                    <b-form-input
                        id="email"
                        v-model="form.email"
                        :placeholder='$t("header.modal.email_placeholder")'
                        type="email"
                        readonly
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </div>
            <div v-else>
              <b-form-group id="email-group" :label='$t("header.modal.email_label")' label-for="email">
                <b-form-input
                    id="email"
                    v-model="form.email"
                    :placeholder='$t("header.modal.email_placeholder")'
                    type="email"
                    readonly
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="phone-group" :label='$t("header.modal.phone_number_label")' label-for="phone">
                <b-form-input
                    id="phone"
                    v-model="form.phoneNr"
                    type="tel"
                    :placeholder='$t("header.modal.phone_number_placeholder")'
                    required
                ></b-form-input>
              </b-form-group>
            </div>
            <b-form-group id="image-group" :label='$t("header.modal.image_label")' label-for="image">
              <b-form-file
                  id="image"
                  accept="image/*"
                  :placeholder='$t("header.modal.image_placeholder")'
                  :drop-placeholder='$t("header.modal.image_drop_placeholder")'
                  @change="uploadImage"
              ></b-form-file>
            </b-form-group>
            <b-button type="submit" variant="primary">{{$t('header.modal.form_submit_button')}}</b-button>
            <b-button type="reset" variant="danger">{{$t('header.modal.form_reset_button')}}</b-button>
          </b-form>
        </div>
        <div v-else>
          <h6>Select your role.</h6>
          <b-button @click="form.type='DISPATCHER'" class="m-2">{{$t('header.modal.role_dispatcher')}}</b-button>
          <b-button @click="form.type='TERMINAL_MANAGER'" class="m-2">{{$t('header.modal.role_terminal_manager')}}</b-button>
          <b-button @click="form.type='WAREHOUSE_MANAGER'" class="m-2">{{$t('header.modal.role_warehouse_manager')}}</b-button>
        </div>
      </div>
      <div v-else-if="state==='pending'">
        <h3>{{$t('header.modal.request_pending_title')}}</h3>
        <h6>{{$t('header.modal.request_pending_text')}}</h6>
      </div>
      <div v-else-if="state==='accepted'">
        <h3>{{$t('header.modal.request_accepted_title')}}</h3>
        <h6>{{$t('header.modal.request_accepted_text')}}</h6>
      </div>
      <div v-else>
        <h3>{{ $t('header.insufficientPermission') }}</h3>
        <h6>{{ $t('header.permissionHelp') }}</h6>
      </div>
    </b-modal>
    <div class="text-center navbar-brand-wrapper d-flex align-items-center">
      <a class="navbar-brand brand-logo">
        <router-link class="nav-link" to="/">
          <img style="max-width: 80%" src="@/assets/images/logo.svg" alt="logo" class="logo-dark"/>
          <img src="@/assets/images/logo-light.svg" alt="logo-light" class="logo-light">
        </router-link>
      </a>
      <a class="navbar-brand brand-logo-mini">
        <router-link class="nav-link" to="/events"><img src="@/assets/images/logo-mini.svg" alt="logo"/></router-link>
      </a>
      <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize"
              @click="toggleSidebar()">
        <span class="icon-menu"></span>
      </button>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <h5 class="mb-0 font-weight-medium d-none d-lg-flex">{{ $t('header.headerTitle') }}</h5>
      <ul class="navbar-nav navbar-nav-right">
        <b-nav-item-dropdown right class="preview-list navbar-dropdown ml-2 d-none d-md-flex">
          <template slot="button-content">
            <div v-if="this.$i18n.locale==='en'" class="nav-link count-indicator message-dropdown p-0 m-0">
              <div class="d-inline-flex mr-3">
                <country-flag country='us' rounded style="align-self: baseline"/>
              </div>
              <span class="profile-text font-weight-normal"> {{ $t('header.english') }}</span>
            </div>
            <div v-if="this.$i18n.locale==='de'" class="nav-link count-indicator message-dropdown p-0 m-0">
              <div class="d-inline-flex mr-3">
                <country-flag country='de' rounded style="align-self: baseline"/>
              </div>
              <span class="profile-text font-weight-normal"> {{ $t('header.german') }}</span>
            </div>
            <div v-if="this.$i18n.locale==='es'" class="nav-link count-indicator message-dropdown p-0 m-0">
              <div class="d-inline-flex mr-3">
                <country-flag country='es' rounded style="align-self: baseline"/>
              </div>
              <span class="profile-text font-weight-normal"> {{ $t('header.spanish') }}</span>
            </div>
          </template>
          <b-dropdown-item @click="changeLocale('en')">
            <a class="preview-item d-flex align-items-center">
              <country-flag country='us' rounded style="align-self: baseline"/>
              <div class="m-2">{{ $t('header.english') }}</div>
            </a>
          </b-dropdown-item>
          <b-dropdown-item @click="changeLocale('de')">
            <a class="preview-item d-flex align-items-center">
              <country-flag country='de' rounded style="align-self: baseline"/>
              <div class="m-2">{{ $t('header.german') }}</div>
            </a>
          </b-dropdown-item>
          <b-dropdown-item @click="changeLocale('es')">
            <a class="preview-item d-flex align-items-center">
              <country-flag country='es' rounded style="align-self: baseline"/>
              <div class="m-2">{{ $t('header.spanish') }}</div>
            </a>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right class="preview-list user-dropdown navbar-dropdown ml-3">
          <template slot="button-content">
            <div class="nav-link count-indicator message-dropdown p-0 m-0 ml-1">
              <b-avatar variant="primary" :text="monogram"></b-avatar>
              <span class="font-weight-normal m-2">{{ userName }}</span>
            </div>
          </template>
          <b-dropdown-item @click="$router.push('/profile')">
            <a class="preview-item d-flex align-items-center">
              <i class="dropdown-item-icon icon-user text-primary"></i> {{ $t('header.myProfile') }}
            </a>
          </b-dropdown-item>
          <b-dropdown-item @click="$router.push('/logout')">
            <a class="preview-item d-flex align-items-center">
              <i class="dropdown-item-icon icon-power text-primary"></i>{{ $t('header.signOut') }}
            </a>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
              @click="toggleMobileSidebar()">
        <span class="icon-menu"></span>
      </button>
    </div>
  </b-navbar>
</template>

<script>

let headerName ='$t("header.modal.email_label")';

export default {
  data() {
    return {
      dispatcherImage: {blank: true, blankColor: '#777', width: 500, height: 500, class: 'm1'},
      headerName: headerName,
      userName: this.$t('header.unknownUser'),
      monogram: "UU",
      userAccess: false,
      state: null,
      size: null,
      form: {
        type: null,
        firstName: '',
        lastName: '',
        companyName: '',
        companyTaxId: '',
        email: '',
        phoneNr: '',
        image: ''
      },
      show: true,
    }
  },
  name: 'app-header',
  mounted() {
    this.checkState()
    this.loadInfo()
    const uname = localStorage.getItem("username")
    const mgram = localStorage.getItem("monogram")
    const user = localStorage.getItem("wtm_user")
    if (uname) {
      this.userName = uname
    }
    if (mgram) {
      this.monogram = mgram
    }
    if (user) {
      this.userAccess = user === "true"
    }
    if (!this.userAccess) {
      this.$bvModal.show('prevent-access')
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      fetch('/api/me/register', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.form),
      })
          .then(response => {
            if (response.ok) {
              this.displayToast(this.$t("header.save_successful"), 'success')
              this.checkState()
            } else {
              this.displayToast(this.$t("header.save_failed"), 'danger')
            }
          })
          .catch((error) => {
            this.displayToast(this.$t("header.request_failed") + '\n' + error, 'danger')
          });
    },
    onReset(event) {
      event.preventDefault()
      this.form.companyName = ''
      this.form.companyTaxId = ''
      this.form.phoneNr = ''
      this.form.image = ''
      this.dispatcherImage = {blank: true, blankColor: '#777', width: 150, height: 150, class: 'm1'}
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.dispatcherImage = e.target.result;
        this.form.image = e.target.result.split(',')[1];
      }
    },
    checkState() {
      fetch("/api/me/state", {
            headers: new Headers({
              'Authorization': 'Bearer ' + localStorage.getItem("token")
            })
          }
      ).then((data) => {
        data.json().then((json) => {
          this.state = json.state
          if (this.state !== "accepted") {
            this.$bvModal.show('prevent-access')
          }
        })
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
        this.$bvToast.toast(this.$t("header.query_error") + error.toString(), {
          role: 'alert',
          title: this.$t("header.query_error_title"),
          variant: 'danger',
          solid: true
        })
      })
    },
    changeLocale(lang) {
      this.$i18n.locale = lang
    },
    handleClose(bvModalEvent) {
      this.$router.push('/logout')
      bvModalEvent.preventDefault()
    },
    toggleSidebar: () => {
      document.querySelector('body').classList.toggle('sidebar-icon-only');
    },
    toggleMobileSidebar: () => {
      document.querySelector('#sidebar').classList.toggle('active');
    },
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        role: 'alert',
        title: variant === 'success' ? 'Success' : 'Error',
        variant: variant,
        solid: true
      })
    },
    loadInfo() {
      fetch("/api/me/info", {
            headers: new Headers({
              'Authorization': 'Bearer ' + localStorage.getItem("token")
            })
          }
      ).then((data) => {
        data.json().then((json) => {
          this.form.firstName = json.firstName
          this.form.lastName = json.lastName
          this.form.email = json.email
        })
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
        this.$bvToast.toast(this.$t("header.query_error") + error.toString(), {
          role: 'alert',
          title: this.$t("header.query_error_title"),
          variant: 'danger',
          solid: true
        })
      })
    }
  }
}
</script>

<style scoped>
</style>